*{
  margin: 0;
  padding: 0;
  box-shadow: none;
}
.mainContainer{
  width: 100%;
  height: 100vh;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}
.container{

  width: 70%;
  height: 80%;

}
.image-container { position: relative; top: 50%; left: 50%; transform: translate(-50%, -50%);}
 .img {
   width:100%;
  height: 100%;  object-fit: cover;
   overflow: hidden;
} 

.heading{
  position: absolute;
  font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif ;
  font-size: 30px;
  top: 75%;
  left: 50%;
  text-align: center;
  transform: translate(-50%,-50%);
}
@media screen and  (max-width: 768px) {
  .container{
     
    width: 100%;
  }
   .img {
     width:100%;
    height: 400px; 
     overflow: hidden;
     position: relative; 
  } 
  
  .heading{
    position: absolute;
    width: 100%;
    font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif ;
    font-size: 30px;
    left: 50%;
    text-align: center;
    transform: translate(-50%,-50%);
  }
}
@media screen and  (min-width: 768px) and (max-width: 1366px) {
  .container{

    width: 100%;
    height: 100%;
  
  }
   .img {
     width:100%;
    height: 100%; 
     overflow: hidden;
     position: relative; 
  } 
  
  .heading{
    position: absolute;
    width: 100%;
    font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif ;
    font-size: 27px;
    top: 65%;
    left: 50%;
    text-align: center;
    transform: translate(-50%,-50%);
  }
}
